import { useQueryClient } from "@tanstack/vue-query";
import { useNuxtApp } from "#imports";
import useNotify from "~/composables/useNotify";
import {
  createQuery,
  createMutation,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { convertMetadataArrayToObjClient } from "~/utils/metadataHelpers";
import { getErrorMessagesFromError } from "~/utils/helpers";

import type { InternalApi } from "nitropack";
import type { LibraryViewModel } from "../models/Library/Library.viewmodel";

type AddLibraryPayload = {
  name: string;
  description?: string;
  internalNotes?: string;
  metadata?: LibraryViewModel["metadata"];
  filter?: string;
};

type ListLibrariesRequest = {
  localOrShared: "shared" | "local";
  page?: number;
  pageSize?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
};

export const useLibraryService = () => {
  const { $api } = useNuxtApp();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();
  const {
    $i18n: { t },
  } = useNuxtApp();

  // List Libraries
  const listLibraries = async (
    {
      page,
      pageSize,
      search,
      sortBy = "name",
      sortOrder = "asc",
      localOrShared,
    }: ListLibrariesRequest,
    signal?: AbortSignal
  ) => {
    return await $api<InternalApi["/api/hcd/libraries"]["get"]>(
      `/api/hcd/libraries`,
      {
        query: {
          page: page,
          size: pageSize,
          filter: search,
          sortBy: sortBy,
          sortOrder: sortOrder,
          localOrShared,
        },
        signal,
      }
    );
  };

  const useListLibrariesQuery = (
    req = {
      page: 1,
      pageSize: 20,
    } as ListLibrariesRequest,
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Libraries.list, req],
      ({ signal }) =>
        listLibraries(
          {
            page: req.page,
            pageSize: req.pageSize,
            localOrShared: req.localOrShared,
          },
          signal
        ),
      {
        createNuxtError,
      }
    );

  // Get Library
  const getLibrary = async (id: string, signal?: AbortSignal) =>
    await $api<InternalApi["/api/hcd/libraries/:id"]["get"]>(
      `/api/hcd/libraries/${id}`,
      { signal }
    );

  const useGetLibraryQuery = (id: string, options?: MyQueryOptions) =>
    createQuery(
      [QUERY_KEYS.Libraries.get, id],
      ({ signal }) => getLibrary(id, signal),
      options
    );

  // Add Library
  const addLibrary = async (payload: AddLibraryPayload) => {
    const newPayload = {
      ...payload,
      metadata: payload.metadata
        ? convertMetadataArrayToObjClient(payload.metadata) // TODO - do on BFF
        : {},
    };
    return await $api(`/api/hcd/libraries`, {
      method: "POST",
      body: newPayload,
    });
  };

  const useAddLibraryMutation = () =>
    createMutation((payload: AddLibraryPayload) => addLibrary(payload), {
      onError() {
        notifyError("Error adding library");
      },
      onSuccess() {
        notifySuccess("Success", "Library successfully added");
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Libraries.list],
        });
      },
    });

  // Update Library
  const updateLibrary = async (id: string, payload: AddLibraryPayload) =>
    await $api(`/api/hcd/libraries/${id}`, {
      method: "PUT",
      body: payload,
    });

  const useUpdateLibraryMutation = () =>
    createMutation(
      ({ id, payload }: { id: string; payload: AddLibraryPayload }) =>
        updateLibrary(id, payload),
      {
        onSuccess: (_x, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.get, y.id],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.list],
          });
          notifySuccess("Success", "Library updated");
        },
        onError: () => {
          notifyError("Error updating Library");
        },
      }
    );

  // Delete Library
  const deleteLibrary = async (id: string) =>
    await $api(`/api/hcd/libraries/${id}`, {
      method: "DELETE",
    });

  const useDeleteLibraryMutation = () =>
    createMutation(({ id }: { id: string }) => deleteLibrary(id), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Libraries.list],
        });
        notifySuccess("Success", "Library deleted");
      },
      onError: () => {
        notifyError(`Error deleting library`);
      },
    });

  // Enable / Disable Library
  const setLibraryStatus = async (id: string, enable: boolean) =>
    await $api(`/api/hcd/libraries/${id}/${enable ? "enable" : "disable"}`, {
      method: "PUT",
    });

  const useSetLibraryStatusMutation = () =>
    createMutation(
      ({ id, enable }: { id: string; enable: boolean }) =>
        setLibraryStatus(id, enable),
      {
        onSuccess: (_, { id, enable }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.get, id],
          });
          notifySuccess(
            "Success",
            `Library has been ${enable ? "enabled" : "disabled"}`
          );
        },
        onError: (error, y) => {
          notifyError(`Error ${y.enable ? "enabling" : "disabling"} library`);
        },
      }
    );

  const exportLibrary = async (id: string) => {
    const blob = await $api.raw(`/api/hcd/libraries/${id}/export`, {
      method: "GET",
      responseType: "arrayBuffer",
    });

    let filename = "";
    const disposition = blob.headers.get("Content-Disposition");
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename=((\\?['"])(.*?)\2|([^;\n]*))/;
      const matches = filenameRegex.exec(disposition);
      if (matches) {
        filename = (matches[3] ?? "") + (matches[4] ?? "");
      }
    }
    if (!filename) {
      filename = `library-${id}.json`;
    }

    const data = blob.body as unknown;

    return {
      data,
      filename,
    };
  };

  const useExportLibraryMutation = (
    onSuccess?: (x: Awaited<ReturnType<typeof exportLibrary>>) => unknown
  ) =>
    createMutation(
      async (id: string) => {
        return await exportLibrary(id);
      },
      {
        onSuccess,
        onError: () => {
          notifyError(t("library.export_error"));
        },
      }
    );

  const importLibrary = async (body: {
    importFile: File;
    password: string;
  }) => {
    const formData = new FormData();
    formData.append("importFile", body.importFile);
    return await $api<InternalApi["/api/hcd/libraries/import"]["post"]>(
      `/api/hcd/libraries/import`,
      {
        method: "POST",
        body: formData,
      }
    );
  };

  const useImportLibraryMutation = () =>
    createMutation(
      (body: { importFile: File; password: string }) => importLibrary(body),
      {
        onSuccess: () => {
          notifySuccess("Success", "Library imported");
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.list],
          });
        },
        onError: (error) =>
          notifyError(getErrorMessagesFromError(error).join("\n")),
      }
    );

  return {
    exportLibrary,
    useExportLibraryMutation,
    listLibraries,
    useListLibrariesQuery,
    useAddLibraryMutation,
    useGetLibraryQuery,
    getLibrary,
    useUpdateLibraryMutation,
    useDeleteLibraryMutation,
    useSetLibraryStatusMutation,
    useImportLibraryMutation,
  };
};
